<template>
   <section id="page-locations" class="mt-8">
      <v-card color="white" elevation="12" class="pa-2 mx-6 mb-6" outlined tile>
         <v-card-title class="align-start">
            <v-sheet color="accent" class="mt-n10" style="z-index: 1;" elevation="6" rounded>
               <v-icon dark size="52" class="pa-6">
                  mdi-map-marker-radius
               </v-icon>
            </v-sheet>
         </v-card-title>
         <v-card flat outlined tile class="pull-top">
            <div class="grey lighten-4 pa-6 pt-5 space-left d-flex space-between">
               <div>
                  <base-title :title="`${location.text || location.kind || 'Location'} Directory`" class="text-uppercase mt-n1" space="0" />
                  <base-subtitle title="Fill all mandatory fields marks with asterisk *." />
               </div>
               <v-spacer />
               <v-select v-model="location" :items="locations.filter(j => $hasRole(j.kind.toLowerCase(), 'R'))" label="Location Type" placeholder="Pilih Lokasi" outlined hide-details class="mt-n1" style="max-width: 300px;"
                  item-text="kind"
                  :return-object="true" :menu-props="{ bottom: true, offsetY: true }"
                  @change="changeLocation"
                  >
                     <template slot="selection" slot-scope="{ item }">
                        {{ item.text || item.kind }}
                     </template>
                     <template v-slot:item="data">
                        <span :class="{ 'grey--text text--lighten-1': [].includes(data.item.kind) }">
                           {{ data.item.text || data.item.kind }}
                        </span>
                     </template>
               </v-select>
            </div>
            <template v-if="location.kind && $hasRole(location.kind.toLowerCase(), 'R')">
               <v-row v-if="isUsed">
                  <v-col cols="12" class="pt-0 d-flex">
                     <v-toolbar flat height="80">
                        <v-spacer />
                     </v-toolbar>
                  </v-col>
               </v-row>
               <v-divider />
               <div v-if="location.kind === 'Building'">
                  <n-building-form :location="location" :zones="zones" @update="updateRecord" />
                  <n-building-table :location="location" :records="records" :zones="zones" :imports="importRecord" @delete="deleteRecord" />
               </div>
               <div v-if="location.kind === 'Floor'">
                  <n-floor-form :location="location" :buildings="buildings" @update="updateRecord" />
                  <n-floor-table :location="location" :records="records" :buildings="buildings" :imports="importRecord" @delete="deleteRecord" />
               </div>
               <div v-if="location.kind === 'Room'">
                  <n-room-form :location="location" :buildings="buildings" :floors="floors" @update="updateRecord" />
                  <n-room-table :location="location" :records="records" :buildings="buildings" :floors="floors" :imports="importRecord" @delete="deleteRecord" />
               </div>
            </template>
         </v-card>
      </v-card>
   </section>
</template>

<script>
import { flatten } from '@/utils/nirtara'
import { building, floor, room } from '@/utils/fields.js'
export default {
   name: 'SectionPageLocations',

   components: {
      NBuildingForm: () => import('@/components/location/NBuildingForm'),
      NBuildingTable: () => import('@/components/location/NBuildingTable'),
      NFloorForm: () => import('@/components/location/NFloorForm'),
      NFloorTable: () => import('@/components/location/NFloorTable'),
      NRoomForm: () => import('@/components/location/NRoomForm'),
      NRoomTable: () => import('@/components/location/NRoomTable'),
   },

   data: () => ({
      isUsed: false,
      locations: [
         { kind: 'Building', id: -1 },
         { kind: 'Floor', id: -1 },
         { kind: 'Room', id: -1 },
      ],
      location: {},
      records: [],
      zones: [],
      buildings: [],
      floors: [],
   }),

   created () {
      this.$waitSetting(zone => this.$socket.on(`client/${this.$store.state.zone}/Location`, this.handleRecord))
   },
   mounted () {
      this.changeLocation(this.$route.params.kind ? this.$route.params : this.locations.find(j => j.kind === 'Floor') || this.locations[0])
   },
   destroyed () {
      this.$socket.off(`client/${this.$store.state.zone}/Location`, this.handleRecord)
   },
   methods: {
      changeLocation (json) {
         this.location = Object.assign({}, this.$hasRole(json.kind.toLowerCase(), 'R') ? json : this.locations.find(j => this.$hasRole(j.kind.toLowerCase(), 'R')) || this.locations[0])
         this.selectRecord()
      },
      async selectRecord (kind, skip) {
         !skip && (this.records = [])
         !kind && (kind = this.location.kind)
         var data = {
            conditions: { kind: kind },
            headers: { command: 'selectLocation' },
            options: { limit: skip ? 600 : 60, skip: skip || 0 },
         }
         this.$store.state.user.code === 'SPA' && (data.conditions.zone = '*')
         // console.log('selectRecord (data)', JSON.parse(JSON.stringify(data)))
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('selectRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         if (response.data.Message) this.$store.commit('snackNotification', response.data)
         else {
            switch (kind) {
               case 'Building': this.records = response.data.map(json => Object.assign({}, building, flatten(json)))
                  this.zones = await this.selectParent({ kind: 'Management' }, { identity: 1, zone: 1, name: '$object.name' })
                  break
               case 'Floor': this.records = response.data.map(json => Object.assign({}, floor, flatten(json)))
                  this.buildings = await this.selectParent({ kind: 'Building' }, { identity: 1, name: '$object.name' }, 'Location')
                  break
               case 'Room': this.records = response.data.map(json => Object.assign({}, room, flatten(json)))
                  this.buildings = await this.selectParent({ kind: 'Building' }, { identity: 1, name: '$object.name' }, 'Location')
                  this.floors = await this.selectParent({ kind: 'Floor' }, { identity: 1, name: '$object.name', building: '$object.building' }, 'Location')
                  break
            }
            // console.log(`selectRecord ( ${kind} = ${this.records.length} )`, JSON.parse(JSON.stringify(this.records)))
         }
      },
      selectParent (conditions, projection, type) {
         return new Promise((resolve) => {
            var data = {
               conditions: conditions ?? {},
               projection: projection ?? {},
               headers: { command: `select${type ?? 'Option'}` },
            }
            this.$store.state.user.code === 'SPA' && (data.conditions.zone = '*')
            this.$store.dispatch('mongoose', data)
            .then(response => resolve(response.data.map(json => projection ? json : flatten(json)))).catch(() => resolve([]))
         })
      },
      async searchRecord (type, filter, callback) {
         var data = {
            conditions: filter,
            projection: { identity: 1, name: '$object.name' },
            headers: { command: `select${type}` },
         }
         // console.log('searchRecord (data)', JSON.parse(JSON.stringify(data)))
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('searchRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         if (response.data.Message) this.$store.commit('snackNotification', response.data)
         else callback(response.data)
      },
      async updateRecord (item) {
         const images = []
         item.View && images.push({ View: [item.View] }) // { View: item.View ? [`${item.View.substr(0, 32)}....`] : [] },
         var array = []
         var data = {
            update: {
               id: typeof item.id === 'string' ? item.id : undefined,
               site: item.site,
               kind: item.kind,
               zone: item.zone,
               identity: item.identity || '*',
               array: array,
               object: {
                  [item.kind]: images,
                  name: item.name,
                  // below added for building
                  // below added for floor
                  access: item.access,
                  building: item.building,
                  // below added for room
                  floor: item.floor,
               },
            },
            headers: { command: 'updateLocation' },
         }
         // console.log('updateRecord ()', '\nitem:', JSON.parse(JSON.stringify(item)), '\ndata:', JSON.parse(JSON.stringify(data)))
         // if (data) return
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('updateRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         response.data.Message && this.$store.commit('snackNotification', response.data)
         if (response.data.Location) {
            this.location.id = -1
            this.$store.commit('snackNotification', { Message: [{ Success: [], note: `${response.data.kind} updated successfully` }], kind: 'Success' })
         }
      },
      async importRecord (rows, callback) {
         var data = {
            import: rows,
            headers: { command: 'importLocation' },
         }
         // console.log('importRecord (data)', JSON.parse(JSON.stringify(data)))
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('importRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         if (response.data.Message) this.$store.commit('snackNotification', response.data)
         else callback(Array.isArray(response.data) ? response.data.length : 0)
      },
      async deleteRecord (item) {
         var data = {
            delete: { kind: item.kind, id: item.id || '*' },
            headers: { command: 'deleteLocation' },
         }
         // console.log('deleteRecord (item)', JSON.parse(JSON.stringify(item)))
         // if (data) return
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('deleteRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         if (response.data.Message) this.$store.commit('snackNotification', response.data)
         else if (response.data.Location) this.$store.commit('snackNotification', { Message: [{ Success: [], note: `${response.data.kind} deleted successfully` }], kind: 'Success' })
         else if (Array.isArray(response.data)) this.$store.commit('snackNotification', { Message: [{ Success: [], note: `${response.data.length} ${item.kind}s deleted successfully` }], kind: 'Success' })
      },
      handleRecord (item) {
         // console.log('handleRecord (item)', JSON.parse(JSON.stringify(item)))
         const { records, location } = this
         item.Content.forEach(record => {
            if (record.kind !== location.kind) return
            const idx = records.findIndex(j => j.id === record.id)
            idx >= 0 && records.splice(idx, 1)
            records.splice((60 + 600 * 16) - 1, records.length - 9659) // (60 + 600 * 16) = 9660
            if (['Create', 'Update', 'Import'].includes(item.action)) { //, 'Delete'
               const template = record.kind === 'Building' ? building : record.kind === 'Floor' ? floor : record.kind === 'Room' ? room : null
               records.unshift(Object.assign({}, template, flatten(record)))
            }
         })
      },
   },
}
</script>

<style lang="scss" scoped>
.pull-top {
   margin-top: -92px;
}
.space-left {
   padding-left: 136px !important;
   height: 90px;
}
</style>
